import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { SOLAR_APP_ID } from '.';
import { ROUTES } from '../../helpers/constants';
import { toastr } from '../../components/CustomToast';

const postSolarMeter = createAsyncThunk(
  'solar/postSolarMeter',
  async (newMeter, { dispatch, getState }) => {
    const stateMeters = getState().solar.meters;
    let meters = cloneDeep(stateMeters);
    try {
      dispatch(showLoading());
      const { meter } = await new WebAPIClient(SOLAR_APP_ID).POST(
        '/resource/meters',
        newMeter
      );

      toastr.success({ title: 'Meter created' });
      return {
        meters: [...meters, meter],
        newMeter: meter,
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putSolarMeter = createAsyncThunk(
  'solar/putSolarMeter',
  async (updatedMeter, { dispatch, getState }) => {
    const stateMeters = getState().solar.meters;
    let meters = cloneDeep(stateMeters);
    try {
      dispatch(showLoading());

      const meter = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/meters/${updatedMeter.org_id}/${updatedMeter.meter_id}`,
        updatedMeter
      );
      remove(meters, {
        org_id: updatedMeter.org_id,
        meter_id: updatedMeter.meter_id,
      });
      meters = concat(meters, meter);

      toastr.success({ title: 'Meter updated' });
      return { meters };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteSolarMeter = createAsyncThunk(
  'solar/deleteSolarMeter',
  async (deletedMeter, { dispatch, getState }) => {
    const stateMeters = getState().solar.meters;
    let meters = cloneDeep(stateMeters);
    try {
      dispatch(showLoading());
      await new WebAPIClient(SOLAR_APP_ID).DELETE(
        `/resource/meters/${deletedMeter.org_id}/${deletedMeter.meter_id}`
      );

      remove(meters, {
        org_id: deletedMeter.org_id,
        meter_id: deletedMeter.meter_id,
      });

      toastr.success({ title: 'Meter deleted' });
      return { meters };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
      dispatch(push(ROUTES.LIGHTLEVEL.path));
    }
  }
);

const updateProductionSum = createAsyncThunk(
  'solar/updateProductionSum',
  async ({ orgId, meterId, year, month }, { dispatch, getState }) => {
    const stateMeters = getState().solar.meters;
    let meters = cloneDeep(stateMeters);
    try {
      dispatch(showLoading());
      const meter = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/update_production_sum/${orgId}/${meterId}`,
        { year, month }
      );
      remove(meters, { org_id: meter.org_id, meter_id: meter.meter_id });
      meters = concat(meters, meter);

      toastr.success({ title: 'Production sum updated' });
      return { meters };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { postSolarMeter, putSolarMeter, deleteSolarMeter, updateProductionSum };
