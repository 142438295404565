import React, { useState } from 'react';
import startCase from 'lodash/startCase';

import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import IntervalView from './IntervalView';
import ProductionSumsView from './ProductionSumsView';

const INTERVAL = 'interval-data';
const PRODUCTION = 'production-sums';
const VIEWS = [INTERVAL, PRODUCTION];

export default function Backfill() {
  const [meter, setMeter] = useState(null);
  const [selectedView, setSelectedView] = useState(VIEWS[0]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ width: '100%', px: 1, mt: 1, mb: 6 }}>
      <Grid size={{ xs: 12 }}>
        <Stack direction='row' justifyContent='flex-start' alignItems='center'>
          {VIEWS.map((view, idx) => {
            return (
              <Link
                key={view + idx}
                onClick={() => setSelectedView(view)}
                variant='h6'
                align='left'
                ml={idx === 0 ? 1 : 2}
                underline={view === selectedView ? 'none' : 'hover'}
                sx={{ fontWeight: view === selectedView ? 800 : 100 }}>
                {startCase(view)}
              </Link>
            );
          })}
        </Stack>
      </Grid>
      {selectedView === INTERVAL && (
        <IntervalView meter={meter} setMeter={setMeter} />
      )}
      {selectedView === PRODUCTION && (
        <ProductionSumsView meter={meter} setMeter={setMeter} />
      )}
    </Grid>
  );
}
