import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import numeral from 'numeral';
import find from 'lodash/find';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@mui/material/styles/useTheme';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import MuiTooltip from '@mui/material/Tooltip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { openConfirmDialog } from '../../../../store/dialogs';
import { SOLAR_APP_ID, updateProductionSum } from '../../../../store/solar';
import WebAPIClient, { errorResponseToastr } from '../../../../api';
import { displaykW } from '../../../../helpers/display-energy';
import { formatTimestamp, generateTicks } from '../../../../helpers/date';

import ChartTooltip from './Tooltip';
import SelectMeter from './SelectMeter';
import TimezoneField from './TimezoneField';
import { TIMEZONES } from '../../../../components/Selectors/SelectTimezone';
import WidgetLoader from '../../../../components/Loaders/WidgetLoader';

export default function ProductionSumsView(props) {
  const { meter, setMeter } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [month, setMonth] = useState(dayjs().subtract(1, 'month'));
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const [timezone, setTimezone] = useState(TIMEZONES[0]);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [actualSum, setActualSum] = useState(0);
  const [savedSum, setSavedSum] = useState(0);

  const fetchChartData = useCallback(async () => {
    setLoading(true);
    try {
      const records = await new WebAPIClient(SOLAR_APP_ID).GET(
        `/resource/timestream/${
          meter.org_id
        }/${start.valueOf()}/${end.valueOf()}`,
        { deviceId: meter.meter_id }
      );
      setChartData(records);
    } catch (err) {
      console.error(err);
      errorResponseToastr(err);
    } finally {
      setLoading(false);
    }
  }, [meter, start, end]);

  useEffect(() => {
    setStart(month.startOf('month'));
    setEnd(month.endOf('month'));
  }, [month]);

  useEffect(() => {
    if (meter && start && end) {
      fetchChartData();
    }
  }, [meter, start, end, fetchChartData]);

  useEffect(() => {
    let sum = chartData.reduce((sum, record) => sum + record.value, 0);
    setActualSum(sum / 4);
  }, [chartData]);

  useEffect(() => {
    if (meter?.energy_stats?.production) {
      const year = month.year();
      const monthNum = month.month() + 1;
      const item = find(
        meter.energy_stats.production,
        (item) => item.year === year && item.month === monthNum
      );
      setSavedSum(item?.value || 0);
    }
  }, [meter?.energy_stats?.production, month]);

  const handleUpdateProductionSum = () => {
    dispatch(
      openConfirmDialog({
        title: 'Update Production Sum',
        message: 'Are you sure you want to update the production sum?',
        onConfirm: () => {
          dispatch(
            updateProductionSum({
              orgId: meter.org_id,
              meterId: meter.meter_id,
              year: month.year(),
              month: month.month() + 1,
            })
          );
        },
      })
    );
  };

  let ticks = [];
  if (start && end) {
    ticks = generateTicks({ start, end });
  }

  const isClose = Math.abs(actualSum - savedSum) < 10;
  return (
    <>
      <Grid size={{ xs: 12 }}>
        <Card raised sx={{ py: 0.5 }}>
          <Stack
            direction='row'
            justifyContent='space-evenly'
            alignItems='center'
            divider={
              <Divider orientation='vertical' flexItem sx={{ my: 0.5 }} />
            }>
            <SelectMeter
              meter={meter}
              setMeter={setMeter}
              setTimezone={setTimezone}
            />
            <TimezoneField timezone={timezone} />
            <DatePicker
              disableFuture={true}
              openTo='month'
              views={['year', 'month']}
              label='Month'
              value={month}
              onChange={(newValue) => {
                setMonth(newValue);
              }}
              timezone={timezone.zone}
              slotProps={{
                textField: {
                  variant: 'standard',
                  InputProps: { disableUnderline: true },
                },
              }}
            />
            <MuiTooltip title='upate production sum' placement='top'>
              <span>
                <IconButton
                  onClick={handleUpdateProductionSum}
                  disabled={!meter || isClose}>
                  <FontAwesomeIcon icon={['fal', 'paper-plane']} />
                </IconButton>
              </span>
            </MuiTooltip>
          </Stack>
        </Card>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Card raised sx={{ py: 0.5, px: 1 }}>
          <WidgetLoader loading={loading} />
          <ResponsiveContainer
            key='production-data-container'
            width='100%'
            height={350}
            style={{ marginBottom: 2 }}>
            <BarChart
              width='100%'
              height={350}
              data={chartData}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
              }}>
              <YAxis
                width={75}
                domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
                tickFormatter={(val, _axis) => {
                  if (val <= 0) return '';
                  return (
                    numeral(Math.round(val).toPrecision(4)).format(
                      '0,0.[000]'
                    ) + ' kW'
                  );
                }}
              />
              <XAxis
                type='number'
                dataKey='timestamp'
                tickMargin={10}
                domain={[start?.unix(), end?.unix()]}
                ticks={ticks}
                tickFormatter={(unixTime) => {
                  return formatTimestamp(unixTime, timezone, 'MMM Do');
                }}
              />
              <Tooltip
                content={<ChartTooltip meter={meter} timezone={timezone} />}
              />
              <Bar
                barSize={10}
                type='monotone'
                dataKey='value'
                stroke='transparent'
                fill={theme.palette.primary.main}
              />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </Grid>
      <Grid
        size={{ xs: 12 }}
        sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <List sx={{ width: '100%', maxWidth: 350 }}>
          <ListItem>
            <ListItemText secondary='Interval Sum: ' />
            <ListItemText
              primary={loading ? '-' : displaykW(actualSum, true)}
              slotProps={{
                primary: {
                  fontWeight: 'bold',
                  align: 'right',
                  sx: { color: (theme) => theme.palette.veregy_colors.blue },
                },
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText secondary='Save Production Sum: ' />
            <ListItemText
              primary={loading ? '-' : displaykW(savedSum, true)}
              slotProps={{
                primary: {
                  fontWeight: 'bold',
                  align: 'right',
                  sx: { color: (theme) => theme.palette.veregy_colors.orange },
                },
              }}
            />
          </ListItem>
        </List>
      </Grid>
    </>
  );
}
